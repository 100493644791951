export const primaryLayout = {
  0: {
    gridColumn: '1/3 span',
    justifyContent: { mobile: 'center', tablet: 'end' },
    gridRow: 1,
    bgColor: '#7590FF',
    descriptionProps: {
      w: { laptop: '526px' },
      left: { laptop: '12px', tablet: '8px', mobile: '0' },
    },
  },
  1: {
    gridRow: 2,
    gridColumn: 1,
    flexDirection: { tablet: 'column' },
    bgColor: '#250E0E',
    align: { tablet: 'center' },
    justify: { tablet: 'end', mobile: 'start' },
  },
  2: {
    gridRow: 2,
    gridColumn: '2 span',
    bgColor: '#DDD',
  },
};

export const secondaryLayout = [];
