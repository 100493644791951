import { Grid } from '@chakra-ui/react';
import React from 'react';
import { layout } from '../model/layoutVariants';

export const ProjectsLayout = ({ projects, config, alter = false, ElementComponent }) => {
  return (
    <Grid
      w="100%"
      display={{ mobile: 'flex', tablet: 'grid' }}
      flexDirection="column"
      {...(!alter ? layout.first : layout.second)}
      gap={{ mobile: '12px', tablet: '16px', laptop: '20px' }}
    >
      {projects?.map((val, i) => (
        <ElementComponent
          key={i}
          h={{ mobile: '440px', tablet: 'auto' }}
          {...val}
          {...config?.[i]}
        />
      ))}
    </Grid>
  );
};
