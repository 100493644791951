import React, { memo } from 'react';
import { Flex } from '@chakra-ui/react';
import SchevronIcon from 'shared/SchevronIcon';
import ProjectDescription from 'shared/ProjectDescription';

const ProjectCard = ({
  bgImage,
  icon,
  description,
  descriptionProps,
  imageProps,
  logoImageProps,
  iconProps,
  tagText,
  ...props
}) => {
  return (
    <Flex
      pos="relative"
      borderRadius={{ laptop: '48px', mobile: '24px' }}
      overflow="hidden"
      width="100%"
      height="100%"
      bgImage={bgImage}
      bgSize="cover"
      bgRepeat="no-repeat"
      {...props}
    >
      <SchevronIcon
        src={icon}
        pos="absolute"
        top={{ laptop: '12px', tablet: '8px', mobile: '4px' }}
        left={{ laptop: '12px', tablet: '8px', mobile: '4px' }}
        boxSize={{ laptop: '72px', mobile: '40px' }}
        imageProps={{
          boxSize: { laptop: '44px', mobile: '24px' },
        }}
        {...iconProps}
      />
      {description && (
        <ProjectDescription description={description} tagText={tagText} {...descriptionProps} />
      )}
    </Flex>
  );
};
export default memo(ProjectCard);
