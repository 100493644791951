import first from 'assets/Cooperations/first.svg';
import second from 'assets/Cooperations/second.svg';
import third from 'assets/Cooperations/third.svg';

export const cooperations = [
  {
    title: 'Team augmentation',
    description: `This model allows you to quickly bring the right tech talent to your project. We select appropriate candidates - and you manage them`,
    img: first,
  },
  {
    title: 'Dedicated team',
    description: `We assign a complete team of specialists who will execute a project under your management. You are in charge of decision making and budget control`,
    img: second,
  },
  {
    title: 'Managed delivery',
    description: `We carry out a project, relieving you of managerial workload. ASTON's specialists launch and conduct all development stages`,
    img: third,
  },
];
