import React, { memo } from 'react';

// import { Link as GatsbyLink } from 'gatsby';
import { Button, VStack } from '@chakra-ui/react';
import { primaryProjects } from 'constants/projects/primary';
import { primaryLayout } from 'constants/projects/projectsLayoutConfigs';
import BlockHeader from 'shared/BlockHeader';
import { ProjectsLayout } from 'shared/ProjectsLayout';
import ProjectCard from '~entities/ProjectCard';
import BlockWrapper from 'shared/BlockWrapper';
import { goToForm } from 'shared/lib/goToForm';

const Projects = () => {
  return (
    <VStack spacing={{ laptop: '40px', tablet: '24px', mobile: '20px' }} w="full">
      <BlockWrapper>
        <BlockHeader>
          Tech Solution <br /> Showcases
        </BlockHeader>
        <ProjectsLayout
          projects={primaryProjects}
          config={primaryLayout}
          ElementComponent={ProjectCard}
        />
      </BlockWrapper>
      {/* <ChakraLink as={GatsbyLink} to="/our-projects" w="full">
        <Button size="secondary" w="full">
          Get In Touch
        </Button>
      </ChakraLink> */}
      <Button size="secondary" w="full" onClick={goToForm}>
        Get In Touch
      </Button>
    </VStack>
  );
};
export default memo(Projects);
