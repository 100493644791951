import HealthcareBg from 'assets/Projects/Isometrics/HealthcareBg.png';
import HealthcareBg_mobile from 'assets/Projects/Isometrics/HealthcareBg_mobile.png';
import TelecomBg from 'assets/Projects/Isometrics/TelecomBg.png';
import TelecomBg_mobile from 'assets/Projects/Isometrics/TelecomBg_mobile.png';
import FinancialServicesBg from 'assets/Projects/Isometrics/FinancialServicesBg.png';
import FinancialServicesBg_mobile from 'assets/Projects/Isometrics/FinancialServicesBg_mobile.png';
import nda from 'assets/Projects/nda.svg';

export const primaryProjects = [
  {
    icon: nda,
    bgImage: { tablet: HealthcareBg, mobile: HealthcareBg_mobile },
    tagText: 'Healthcare',
    description: 'EHR for both inpatient and outpatient healthcare facilities',
  },
  {
    icon: nda,
    bgImage: { tablet: FinancialServicesBg, mobile: FinancialServicesBg_mobile },
    tagText: 'Financial Services',
    description: 'SME-focused mobile banking solution',
  },
  {
    icon: nda,
    bgImage: { tablet: TelecomBg, mobile: TelecomBg_mobile },
    tagText: 'Telecom',
    description: `BSS software QA solution`,
  },
];
