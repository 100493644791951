import React from 'react';
import { Button, Grid, VStack } from '@chakra-ui/react';
import { goToForm } from 'shared/lib/goToForm';
import { services } from 'constants/services';
import BlockHeader from 'shared/BlockHeader';
import ServiceCard from '~entities/ServiceCardMain';

const ServicesMain = () => {
  return (
    <VStack
      spacing={{ laptop: '48px', tablet: '32px', mobile: '24px' }}
      w="full"
      pos="relative"
      zIndex={800}
      align="start"
    >
      <BlockHeader>Services</BlockHeader>
      <VStack spacing={{ laptop: '40px', mobile: '24px' }}>
        <Grid
          w="full"
          gap={{ laptop: '20px', mobile: '16px' }}
          templateColumns={{ mobile: '1fr', tablet: 'repeat(2,1fr)' }}
        >
          {services?.map(val => (
            <ServiceCard key={val.title} {...val} />
          ))}
        </Grid>
        <Button size="secondary" w="full" onClick={goToForm}>
          Get In Touch
        </Button>
      </VStack>
    </VStack>
  );
};
export default ServicesMain;
