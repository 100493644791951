import React, { memo } from 'react';
import { Box, Button, Flex, Img, Text, VStack } from '@chakra-ui/react';
import commonAssets from 'assets/common';
import { goToForm } from 'shared/lib/goToForm';
import AnimatedText from 'shared/AnimatedText';

const GreetingsMain = () => {
  return (
    <Box boxSizing="border-box" w="full" pos="relative" zIndex={800}>
      <VStack
        w="full"
        spacing={{ mobile: '48px', tablet: '64px', laptop: '80px' }}
        align="start"
        pos="relative"
        zIndex={800}
        mb={{ mobile: '180px', tablet: '200px', laptop: '210px' }}
      >
        <Text as="h1" maxW={{ mobile: '90%', tablet: '825px' }} textAlign="left">
          Right IT Solutions&nbsp;for <br /> Your Business
        </Text>
        <Button onClick={goToForm}>Get In Touch</Button>
      </VStack>

      <Flex
        direction={{ mobile: 'column', tablet: 'row' }}
        w="full"
        pos="relative"
        zIndex={800}
        gap={{ mobile: '28px', tablet: '40px', laptop: '20px' }}
      >
        <Box flex={1}>
          <Img
            h={{ mobile: '56px', tablet: 'fit-content' }}
            w={{ mobile: '44px', tablet: 'fit-content' }}
            src={commonAssets.icons.Aston}
            alt=""
          />
        </Box>
        <VStack
          w="full"
          spacing={{ mobile: '40px', tablet: '48px', laptop: '64px' }}
          flex={1}
          align="start"
        >
          <VStack spacing={{ mobile: '40px', tablet: '48px' }} w="full" align="start">
            <AnimatedText duration={1100} as="h3" maxW={{ mobile: '500px', tablet: '720px' }}>
              Boost your product&#39;s revenue with effective software created to meet your business
              needs!
            </AnimatedText>
            <Flex gap={{ mobile: '8px', tablet: '20px' }} w="full" className="greetings__stats">
              <Box flex={1}>
                <Text as="h2">400+</Text>
                <Text>projects</Text>
              </Box>
              <Box flex={1}>
                <Text as="h2">2500+</Text>
                <Text>employees</Text>
              </Box>
            </Flex>
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
};
export default memo(GreetingsMain);
