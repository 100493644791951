import React from 'react';
import { Flex } from '@chakra-ui/react';
const Tag = ({ children, isHover = false, ...props }) => {
  return (
    <Flex
      as="p"
      boxSizing="border-box"
      w="fit-content"
      borderRadius="40px"
      borderWidth={{ laptop: '1.6px', mobile: '1px' }}
      borderStyle="solid"
      borderColor="bg.black"
      textStyle="tag"
      p={{ mobile: '8px 12px', laptop: '8px 16px' }}
      {...props}
    >
      {children}
    </Flex>
  );
};
export default Tag;
