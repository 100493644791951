import React from 'react';
import data from './data.json';
import Head from 'shared/Head';
import IndexPage from 'pages/IndexPage';

export default function Home() {
  return (
    <>
      <Head {...data.head} />
      <IndexPage />
    </>
  );
}
