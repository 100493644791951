import React, { memo } from 'react';
import Cooperation from 'widgets/Cooperation';
import ContactUs from 'widgets/ContactUs';
import Reviews from 'widgets/Reviews';
import Projects from 'widgets/Projects';
import GreetingsMain from 'widgets/GreetingsMain';
import ServicesMain from 'widgets/ServicesMain';
import AnimatedText from 'shared/AnimatedText';
import { cooperations } from 'constants/cooperations';

const IndexPage = () => {
  return (
    <>
      <GreetingsMain />
      <ServicesMain />
      <Projects />
      <Cooperation
        title={
          <AnimatedText
            duration={700}
            as="h2"
            className="coop-title"
            _after={{ content: `'.'`, color: 'bg.accent' }}
          >
            Choose an optimal <br /> cooperation model
          </AnimatedText>
        }
        cardsData={cooperations}
      />
      <Reviews />
      <ContactUs />
    </>
  );
};

export default memo(IndexPage);
