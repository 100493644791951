import mobile from 'assets/Services/Mobile.svg';
import frontend from 'assets/Services/Frontend.svg';
import backend from 'assets/Services/Backend.svg';
import c1 from 'assets/Services/1C.svg';

export const services = [
  {
    title: 'Mobile<br/> development',
    techs: ['iOS', 'Android', 'Flutter', 'React Native'],
    description: `Transforming Your Vision Into Dynamic Mobile Solutions - Where Innovation Meets Mobility!`,
    image: mobile,
  },
  {
    title: 'Front-end<br/> development',
    techs: ['JavaScript', 'React', 'Angular', 'Vue'],
    description: `Transforming Ideas Into User-friendly Interfaces With Front-end Development Expertise - Where Design Meets Functionality!`,
    image: frontend,
  },
  {
    title: 'Back-end<br/> development',
    techs: ['Java', '.NET', 'Python', 'PHP', 'Node', 'Ruby', 'Golang', 'Scala', 'C/C++'],
    description: `Building the Engine of Your Digital Success with Back-end Development - Powering Your Project's Functionality Behind the Scenes`,
    image: backend,
  },
  {
    title: 'UI/UX design',
    techs: ['UX Engineers', 'UI Design', 'Graphic Designers', ' 3D Designers'],
    description: `Creating Digital Magic With Expert UI/UX Design - Where Every Click Counts!`,
    image: c1,
  },
];
