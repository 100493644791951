import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import Tag from 'shared/Tag';

const ProjectDescription = ({ description, tagText, tagProps, ...props }) => {
  return (
    <VStack
      pos="absolute"
      spacing={{ desktop: '64px', laptop: '40px', mobile: '32px' }}
      bottom={{ laptop: '12px', tablet: '8px', mobile: '0' }}
      right={{ laptop: '12px', tablet: '8px', mobile: '0' }}
      borderRadius={{ laptop: '36px', tablet: '16px', mobile: '20px' }}
      bgColor="bg.white"
      h="full"
      w={{ tablet: 'calc(100% - 24px)', mobile: 'calc(100% - 16px)' }}
      m={{ mobile: '8px', tablet: '0' }}
      maxW={{ laptop: '542px', tablet: '276px', mobile: '416px' }}
      maxH={{ desktop: '312px', laptop: '240px', mobile: '162px' }}
      p={{ laptop: '8px', mobile: '4px' }}
      align="start"
      justify="start"
      {...props}
    >
      <Tag p={{ laptop: '16px 24px', mobile: '6px 12px' }} color="#0D0E0C" {...tagProps}>
        {tagText}
      </Tag>
      <Text
        as="h4"
        ms={{ mobile: '12px', laptop: '32px' }}
        color="#23281F"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </VStack>
  );
};
export default ProjectDescription;
